.form-container-form-3 {
    max-width: 600px;
    background-color: rgba(0, 189, 157, 1);
    padding: 2rem;
    box-shadow: 2px 2px 10px 5px rgba(0, 0, 0, 0.2);
}

.p-inputtext-form {
    border-bottom: 2px solid rgb(0, 175, 202);
    border-top: none;
    border-left: none;
    border-right: none;
    font-weight: bold;
    border-radius: 5px;
}

.btn-submit {
    background-color: white;
    border: none;
    color: #4b5563;
}

.p-button-label {
    font-size: 20px !important;
}

.p-inputtext:focus {
    outline: none;
    box-shadow: none;

}

.p-inputgroup {
    width: 100%;
}

.input-zoom {
    transition: transform 0.3s ease-in-out;
}

.input-zoom:hover {
    transform: scale(1.05);
}

.button-validate:hover {
    background-color: rgb(244, 244, 244) !important;
}


.c-white {
    color: white;
    font-size: 20px;
}

@media screen and (max-width: 756px) {
    .form-container {
        margin-left: 2rem;
        margin-right: 2rem;
    }
}
.container-info {
    background-color: white;
    padding: 3rem;
    box-shadow: -10px 10px 5px 0px rgba(101, 101, 101, 0.1)
}

.w-300{
    width: 300px;
}

.p-title{
    font-weight: bold;
    font-size: 17px;
}

@media screen and (max-width: 667px) {
.container-info{
    padding: 0;
}
    
}
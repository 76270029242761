/* FormPage1.css */
.FormPage1 {
  display: flex;
  flex-direction: column;
  background: linear-gradient(to bottom, rgb(178, 237, 249) 40%, rgb(25, 145, 219) 100%);
  min-height: 100vh; /* Assurez-vous que le composant prend tout l'espace disponible */
  }
  
  /* FormPage2.css */
.FormPage2 {
  display: flex;
  flex-direction: column;
  background: linear-gradient(to bottom, rgb(0, 175, 202) 40%, rgb(0, 87, 142) 100%);
  min-height: 100vh; /* Assurez-vous que le composant prend tout l'espace disponible */
  }
  
  .content {
    flex: 1;
  }
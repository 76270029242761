.form-container {
    width: 600px;
    border-radius: 18px;
    background-color: #ffffff;
    padding: 2rem;
    border: 2px solid black;
}

.input-zoom {
    transition: transform 0.3s ease-in-out;
}

.input-zoom:hover{
    transform: scale(1.05);
}

.hover-color{
    background-color: rgb(6, 166, 229);
}

.hover-color:hover{
    background-color: rgb(0, 151, 211);
}


.bg-color{
     background-color: rgb(6, 166, 229);
}

.c-white{
    color: white;
    font-size: 20px;
}

@media screen and (max-width: 756px) {
    .form-container {
        margin-left: 2rem;
        margin-right: 2rem;
    }
}
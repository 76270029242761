.band-bg {
    width: 100%;
    height: 600px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('../../img/standar-telephonique.jpg');
}

.logo-compare {
    width: auto;
    height: 85px;
}

.icon-info {
    height: auto;
    width: 200px;
}

.container-icon {
    width: 300px;
}

.panel-container {
    width: 900px;
}

.form-div {
    max-width: 900px;
}

@media screen and (max-width: 667px) {
    .panel-container {
        width: 340px;
    }
}
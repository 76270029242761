.button-edit-vert {
    font-size: 1.312rem;
    padding: 6px;
    border-radius: 4px;
    line-height: 1.3em;
    background-color: #78b40b;
    color: white;
  }

  .bg-color-header{
    background-color: white;
  }
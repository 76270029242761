.p-field {
    margin-bottom: 20px;
}

.progress-bar {
    color: red !important;
    background-color: rgb(183, 183, 183) !important;
    width: 100%;
}

.p-field-radiobutton {
    border: 1px solid black;
    border-radius: 4px;
    padding: 1rem;
    position: relative;
}

.band-info{
    background-color: rgb(234, 247, 254);
    font-size: 15px;
    padding: 10px;
    border-radius: 10px;
}

.p-field-radiobutton:hover {
    box-shadow: 2px 2px 3px 2px rgb(199, 199, 199);
}

.title-step {
    color: black;
}

.small-font {
    font-size: 15px;
}

.stepper-container {
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 2px rgba(85, 85, 85, 0.2);
}

.p-stepper-nav {
    display: none !important;
}

.stepper-buttons {
    display: flex;
    justify-content: space-between;
}

.p-field-checkbox {
    padding: 1rem;
    border: 1px solid black;
    width: 100%;
    border-radius: 4px;
}

.p-field-checkbox:hover {
    box-shadow: 2px 2px 3px 2px rgb(199, 199, 199);
}


.next-button {
    margin-left: auto;
}

.p-stepper-panels {
    background: none;
}

.selectbutton-step {
    padding: 3rem;
    font-size: 30px;
    height: 100px;
    text-align: center;
}
.block-info {
    background-color: #00bd9d;
    color: white;
    height: 100%;
    padding: 1rem;
    border-radius: 18px;
}

.w-100{
    width: 150px;
}

.list-bold-text{
    font-size: 19px;
    font-weight: bold;
}